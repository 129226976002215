<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="售后单号">
                    <el-input placeholder="请输入售后单号" v-model="queryParams.ordersn" clearable></el-input>
                </el-form-item>
                <el-form-item label="订单编号">
                    <el-input placeholder="请输入订单编号" v-model="queryParams.ordersn_src" clearable></el-input>
                </el-form-item>
                <el-form-item label="退货类型">
                    <el-select clearable placeholder="请选择退款类型" v-model="queryParams.ty" @change="changeQuery">
                        <el-option label="退款" value="1"></el-option>
                        <el-option label="退货" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="申请时间:" prop="time">
                    <el-date-picker ref="drawDate" style="margin-left: 5px;" v-model="queryParams.time" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" clearable @change="changeQuery">
                    </el-date-picker>
                </el-form-item>
                <el-form-item style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </el-form-item>
            </el-form>
            <el-radio-group v-model="tabPosition" style="margin-bottom: 30px;" @change="changState">
                <el-radio-button label="all">全部</el-radio-button>
                <el-radio-button label="1">待审核</el-radio-button>
                <el-radio-button label="2">审核通过</el-radio-button>
                <el-radio-button label="3">拒绝</el-radio-button>
                <el-radio-button label="4">用户取消</el-radio-button>
                <el-radio-button label="5">已完成</el-radio-button>
            </el-radio-group>
            <div class="action">
                <el-button type="primary" @click="exportFile">导出</el-button>
            </div>
            <!-- 表格内容 -->
            <el-table v-loading="loading" :data="pageInfo.list" element-loading-text="数据加载中" stripe border ref="multipleTable" style="margin-top:20px">
                <el-table-column prop="id" label="id" width="80" align="center"></el-table-column>
                <el-table-column prop="uid" label="用户id" width="80" align="center"></el-table-column>
                <el-table-column prop="order.ordersn" label="订单编号" width="200" align="center"></el-table-column>
                <el-table-column prop="pay_number" label="流水单号" width="200" align="center"></el-table-column>
                <el-table-column prop="ordersn" label="售后单号" width="170" align="center"></el-table-column>
                <el-table-column prop="goods.title" label="商品名称" align="center" width="300"></el-table-column>
                <el-table-column prop="goods.attr" label="商品规格" width="170" align="center"></el-table-column>
                <el-table-column label="商品图片" align="center" width="80" prop="goods.thumb">
                    <template slot-scope="scope" class="aa">
                        <el-image @click="showBigImg(scope.$index)" style="height:23px;width:30px;" :class="`column${scope.$index}`" fit="cover" :src="scope.row.goods.thumb" :preview-src-list="[scope.row.goods.thumb]">
                            <div slot="error" class="image-slot">
                                <el-image fit="cover" :src="defaultImg"></el-image>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="goods.number" label="数量" align="center" width="70"></el-table-column>
                <el-table-column prop="goods.price" label="退款金额" align="center" width="70"></el-table-column>
                <el-table-column prop="ctime" label="申请时间" width="160" align="center"></el-table-column>
                <el-table-column prop="reason" label="退款原因" width="150" align="center"></el-table-column>
                <el-table-column label="退货类型" prop="ty" align="center" width="80">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.ty === 1">退款</el-tag>
                        <el-tag type="primary" v-if="scope.row.ty === 2">退货</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="expresssn" label="物流单号" width="170" align="center"></el-table-column>
                <el-table-column label="订单状态" prop="state" align="center" :formatter="getProductState" width="120">
                </el-table-column>
                <el-table-column label="操作" align="center" width="170" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" @click="goDetail(scope.row.id)" class="green">查看详情</el-button>
                        <el-button type="text" @click="state(scope.row.id)" v-if="scope.row.state == 1" class="green">通过
                        </el-button>
                        <el-button type="text" @click="state(scope.row.id, 'refuse')" class="red" v-if="scope.row.state == 1">驳回</el-button>
                        <el-button type="text" v-if="scope.row.state == 2 && scope.row.is_complete!=2" @click="pay_op(scope.row.id)">填写打款单号</el-button>
                        <!-- <el-button @click="check" type="text" class="red"
                        v-if="scope.row.expresscom && scope.row.expresssn">查看物流</el-button>-->
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
        <!-- 上线、下线提示框 -->
        <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
            <div class="del-dialog-cnt">{{ confirmContent }}</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="confirmVisible = false">取 消</el-button>
                <el-button type="primary" @click="stateData">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="填写打款单号" :visible.sync="makeMoneyVisible" width="300px" center>
            <el-input v-model="pay_number" placeholder="填写打款单号" style="width:100%!important"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="makeMoneyVisible = false">取 消</el-button>
                <el-button type="primary" @click="makeMoney">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import { request } from '@/common/request';
import { deal } from '@/common/main';
import { mapMutations, mapState } from 'vuex';

export default {
    components: {
        Breadcrumb,
        Pagination
    },
    data() {
        return {
            tabPosition: 'all',
            defaultImg: require('../../assets/img/zhan.jpeg'),
            menuList: ['订单', '售后订单'],
            pageInfo: {
                list: [],
            },
            goodsList: [],
            show: false,
            logisticsVisible: false,
            confirmContent: '',
            confirmVisible: false,
            makeMoneyVisible: false,
            refunt_state: 0,
            choseState: 0,
            refunt_id: 0,
            pay_number: '',
        }
    },
    computed: {
        queryParams: {
            set: function (newValue) {
                console.log(111111, newValue)
                this.$store.commit('initQueryParams', newValue)
            },
            get: function (newValue) {
                return this.$store.state.queryParams
            },
        }
    },
    created() {
        if (this.queryParams.state) {
            if (this.queryParams.state === 1) {
                this.tabPosition = 1
            }
            if (this.queryParams.state === 2) {
                this.tabPosition = 2
            }
            if (this.queryParams.state === 3) {
                this.tabPosition = 3
            }
            if (this.queryParams.state === 4) {
                this.tabPosition = 4
            }
        } else if (this.queryParams.is_complete) {
            if (this.queryParams.is_complete === 2) {
                this.tabPosition = 5
            }
        } else {
            this.tabPosition = 'all'
        }
        this.loadData()
    },
    watch: {
        $route(from) {
            let ArticleEdit = from.fullPath.indexOf('/order-RefundDetail')
            if (ArticleEdit !== -1) {
                this.loadData()
            }
        }
    },
    methods: {
        ...mapMutations({
            'initQueryParams': 'initQueryParams'
        }),
        // 导出
        exportFile() {
            if (this.queryParams.ctime && this.queryParams.etime) {
                request.get('/return/export', { stime: this.queryParams.ctime, etime: this.queryParams.etime }).then(ret => {
                    if (ret.code == 1) {
                        this.$message.success('导出成功');
                    } else {
                        this.$message.error(ret.msg);
                    }
                });
            } else {
                this.$message.error('请选择时间');
            }

        },
        // 查看大图
        showBigImg(index) {
            this.$nextTick(function () {
                let shows = document.getElementsByClassName(`column${index}`)
                let show = shows[0].getElementsByClassName('el-image-viewer__wrapper')
                show[0].style['display'] = 'block'
                let dom = show[0].getElementsByClassName('el-image-viewer__mask')
                dom[0].addEventListener('click', function () {
                    show[0].style['display'] = 'none'
                })
            })
        },
        state(id, type) {
            this.refunt_id = id;
            if (type === "refuse") {
                this.refunt_state = 3;
                this.confirmContent = '确认拒绝'
            } else {
                this.refunt_state = 2;
                this.confirmContent = '确认通过'
            }
            this.confirmVisible = true
        },
        getProductState(row) {
            let stateTip = '';
            let state = row.state;
            let is_complete = row.is_complete;
            let refund_state = row.refund_state ? row.refund_state : '';
            switch (+state) {
                case 1:
                    stateTip = '待审核'; break;
                case 2:
                    stateTip = '审核通过'; break;
                case 3:
                    stateTip = '已拒绝'; break;
                case 4:
                    stateTip = '买家已取消'; break;
                    break;
            }
            switch (+refund_state) {
                case 1:
                    stateTip = '等待买家退货'; break;
                case 2:
                    stateTip = '买家已发货'; break;
                    break;
                case 3:
                    stateTip = '仓库确认收货'; break;
                    break;
            }
            switch (+is_complete) {
                case 2:
                    stateTip = '已完成';
                    break;
            }
            return stateTip;
        },
        loadData() {
            this.loading = true;
            // 获取售后订单列表
            request.get('/order/rights/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.pageInfo.list = ret.data.list;
                    this.pageInfo.list.map(function (val) {
                        if (val.goods[0]) {
                            val.goods = val.goods[0].list;
                        }
                        return val;
                    });
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        changState() {
            this.queryParams.page = 1
            this.queryParams.pagesize = 10
            switch (this.tabPosition) {
                case 'all':
                    delete this.queryParams.is_complete
                    delete this.queryParams.state
                    break;
                case '1':
                    delete this.queryParams.is_complete
                    this.queryParams.state = 1
                    break;
                case '2':
                    delete this.queryParams.is_complete
                    this.queryParams.state = 2
                    break;
                case '3':
                    delete this.queryParams.is_complete
                    this.queryParams.state = 3
                    break;
                case '4':
                    delete this.queryParams.is_complete
                    this.queryParams.state = 4
                    break;
                case '5':
                    delete this.queryParams.state
                    this.queryParams.is_complete = 2
                    break;
            }
            this.loadData()
        },
        // 分页
        changeQuery() {
            this.queryParams.page = 1;
            if (this.queryParams.time) {
                let arr = Object.assign({}, this.queryParams.time)
                this.queryParams.ctime = deal.timestamp(arr[0]);
                this.queryParams.etime = deal.timestamp(arr[1]);
            } else {
                delete this.queryParams.ctime
                delete this.queryParams.etime
            }
            this.loadData();
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val
            if (this.queryParams.time) {
                let arr = Object.assign({}, this.queryParams.time)
                this.queryParams.ctime = deal.timestamp(arr[0]);
                this.queryParams.etime = deal.timestamp(arr[1]);
            } else {
                delete this.queryParams.ctime
                delete this.queryParams.etime
            }
            this.loadData()
        },
        refreshPageNum(val) {
            this.queryParams.page = val
            if (this.queryParams.time) {
                let arr = Object.assign({}, this.queryParams.time)
                this.queryParams.ctime = deal.timestamp(arr[0]);
                this.queryParams.etime = deal.timestamp(arr[1]);
            } else {
                delete this.queryParams.ctime
                delete this.queryParams.etime
            }
            this.loadData()
        },
        stateData() {
            request.post('/order/rights/edit', { id: this.refunt_id, state: this.refunt_state }).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.loadData();
                } else {
                    this.$message.error(ret.msg);
                }
                this.confirmVisible = false
            });
        },
        pay_op(id) {
            this.refunt_id = id;
            this.makeMoneyVisible = true
        },
        // 查看详情
        goDetail(id) {
            this.$router.replace({ path: '/order-RefundDetail', query: { 'id': id } })
        },
        makeMoney() {
            if (!this.pay_number) { this.$message.error('打款单号不能为空'); }
            this.true = false;
            request.post('/order/rights/edit', { id: this.refunt_id, pay_number: this.pay_number, is_complete: 2 }).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.loadData();
                } else {
                    this.$message.error(ret.msg);
                }
                this.makeMoneyVisible = false
            });
        },
    }
};
</script>

<style lang="scss" scoped>
</style>